let counterData = [
  {
    number: 506,
    text: 'clients satisfied'
  }, {
    number: 30,
    text: 'hospitals supported in Poland'
  }, {
    number: 15,
    text: 'public procurements completed'
  }, {
    number: 8,
    text: 'countries supported'
  }, {
    number: 150,
    text: 'milions gloves sold'
  }
];

export { counterData };