const referencesIntro = {
  heading: 'Testimonials',
  text: 'The effort we put into providing professional service, individual approach to our clients and quick operations has enabled for us to build a strong cooperation net with many medical units in Poland and abroad. Our clientlist contains mostly medical facilities like public hospitals, provincial hospitals, medical clinics, private hospitals, care facilities, social welfare homes, physiotherapy centers and aesthetic medicine clinics. We also work with state units, among which are: the police, post, banks. We actively cooperate with many medical wholesalers. RC-Medical is a member of Polish - Slovak chamber of commerce and industry. We completed orders from Danish, Belgian, Spanish, British, Slovenian, Czech and French clients.',
  button: 'liaison',
  link: '/liaison',
};


const references = [
  {
    quote: "Orders filled with due diligence.",
    company: "Polish Post",
  }, {
    quote: "Professional and trustworthy partner.",
    company: "Bielsko-Biała Pediatric Hospital",
  }, {
    quote: "We recommend RC-Medical as a reliable supplier.",
    company: "Myslowice Health Center",
  }, {
    quote: "Reliable, competent and trustworthy business partner.",
    company: "Moszna Neurosis Therapy Center",
  }, {
    quote: "Deliveries made properly and on time.",
    company: "Kraków Provincial Police Headquarters",
  }, {
    quote: "We recommend Rue Cambon sp.z o.o as a reliable supplier and trustworthy business partner",
    company: "Nursing Home Nazareth in Gliwice",
  }, {
    quote: 'We express our satisfaction with the cooperation and recommend Rue Cambon sp.z o.o',
    company: 'GammaMedica Kraków',
  }, {
    quote: "Cooperation with RC Medical was a pleasure for us. We are grateful for the openness and empathy we experienced on the part of the company's employees",
    company: 'NZOZ of the Congregation of Marian Fathers'
  }
];

const buttonData = 'see more'

export { references, referencesIntro, buttonData };