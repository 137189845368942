const privacyPolicyData = {
  heading: 'The privacy policy describes the rules for the processing of information about you, including personal data and cookies.',
  date: '22.02.2021',
  content: [{
    cHeading: '1. General information',
    cParagraph: "This policy applies to the Website operating at the url: rcmedical.pl. The website operator and the personal data administrator is: Rue Cambon, Mehoffera 10, 31-322 Kraków. Operator's e- mail contact address: admin@rcmedical.pl.The operator is the administrator of your personal data in relation to the data provided voluntarily on the website.The website uses personal data for the following purposes: running a newsletter, conducting online chat conversations, handling inquiries via the form, presenting an offer or information.The website performs the functions of obtaining information about users and their behavior in the following way: through data entered voluntarily in forms, which are entered into the Operators systems, by saving cookies in end devices (so-called cookies).",
  }, {
    cHeading: "2. Selected data protection methods used by the Operator",
    cParagraph: "The places of logging in and entering personal data are protected in the transmission layer (SSL certificate). As a result, personal data and login data entered on the website are encrypted on the user's computer and can only be read on the target server. The operator periodically changes his administrative passwords. An important element of data protection is regular updating of all software used by the Operator to process personal data, which in particular means regular updates of programming components. "
  }, {
    cHeading: "3. Hosting",
    cParagraph: "The website is hosted (technically maintained) on the operator's servers: render.com"
  }, {
    cHeading: "4. Your rights and additional information on how the data is used",
    cParagraph: "In some situations, the Administrator has the right to transfer your personal data to other recipients, if it is necessary to perform the contract concluded with you or to fulfill the obligations incumbent on the Administrator. This applies to such groups of recipients: access to personal data concerning you, rectification, deletion, processing restrictions, and data transfer. You have the right to object to the processing indicated in point 3.3 c) to the processing of personal data in order to perform the legitimate interests pursued by the Administrator, including profiling, but the right to object may not be exercised if there are valid legally justified grounds for processing your interests, rights and freedoms, in particular establishing, investigating or defending claims. The Administrator's actions may be appealed against to the President of the Personal Data Protection Office, ul. Stawki 2, 00-193 Warsaw. Providing personal data is voluntary, but necessary to operate the Website. In relation to you, actions may be taken consisting in automated decision making, including profiling to provide services under the concluded contract and for the purpose of conducting direct marketing by the Administrator. Personal data is not transferred from third countries within the meaning of the provisions on the protection of personal data. This means that we do not send them outside the European Union."
  }, {
    cHeading: "5. Information in the forms",
    cParagraph: "The website collects information provided voluntarily by the user, including personal data, if provided. The website may save information about connection parameters (time stamp, IP address). The website, in some cases, may save information facilitating the linking of data in the form with the e-mail address of the user filling in the form. In this case, the user's e-mail address appears inside the url of the page containing the form. The data provided in the form is processed for the purpose resulting from the function of a specific form, eg in order to process the service request or commercial contact, registration of services, etc. Each time the context and description of the form clearly informs what it is used for."
  }, {
    cHeading: "6. Administrator logs",
    cParagraph: "Information on the behavior of users on the website may be subject to logging. These data are used to administer the website."
  }, {
    cHeading: "7. Important Marketing Techniques",
    cParagraph: "The operator uses statistical analysis of website traffic through Google Analytics (Google Inc. based in the USA). The operator does not provide personal data to the operator of this service, but only anonymised information. The service is based on the use of cookies on the user's end device. In terms of information about user preferences collected by the Google advertising network, the user can view and edit information derived from cookies using the tool: https://www.google.com/ads/preferences/. The operator uses remarketing techniques that allow for adjusting advertising messages to the user's behavior on the website, which may give the illusion that the user's personal data is used to track him, but in practice no personal data is transferred from the Operator to advertising operators. The technological condition for such activities is that cookies are enabled."
  }, {
    cHeading: "8. Information about cookies",
    cParagraph: "The website uses cookies. Cookie files (so-called cookies) are IT data, in particular text files, which are stored on the Website User's end device and are intended for use with the Website's pages. Cookies usually contain the name of the website they come from, the storage time on the end device and a unique number. The entity that places cookies on the Website User's end device and accesses them is the Website operator. Cookies are used for the following purposes: maintaining the Website user's session (after logging in), thanks to which the user does not have to re-enter the login and password on each subpage of the Website; achieving the goals set out above in the section `` Important marketing techniques '' ;. The Website uses two basic types of cookies: session cookies and persistent cookies. Session cookies are temporary files that are stored on the User's end device until logging out, leaving the website or turning off the software (web browser). Persistent cookies are stored on the User's end device for the time specified in the cookie file parameters or until they are deleted by the User. Software for browsing websites (web browser) usually allows cookies to be stored on the User's end device by default. Website Users can change the settings in this regard. The web browser allows you to delete cookies. It is also possible to automatically block cookies.Detailed information on this subject can be found in the help or documentation of the web browser. Restrictions on the use of cookies may affect some of the functionalities available on the Website pages. Cookies placed on the Website User's end device may also be used by entities cooperating with the Website operator, in particular the following companies: Google (Google Inc. based in the USA), Facebook (Facebook Inc. based in the USA), Twitter (Twitter Inc. based in the USA)."
  }, {
    cHeading: "9. Managing cookies - how to express and withdraw consent in practice?",
    cParagraph: "If the user does not want to receive cookies, he may change the browser settings. We reserve that disabling cookies necessary for authentication processes, security, maintaining user preferences may make it difficult, and in extreme cases may prevent the use of websites."
  },]
}

export { privacyPolicyData };